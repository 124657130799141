import { colors } from '@shared/tokens/colors.stylex';
import { typography } from '@shared/tokens/typography.stylex';
import * as stylex from '@stylexjs/stylex';
import { fonts } from '../../shared/tokens/fonts.stylex';

export const styles = stylex.create({
  overlay: {
    fontFamily: fonts.din2014Regular,
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1000',
    visibility: 'visible',
    opacity: 1,
    pointerEvents: 'auto',
  },
  modal: {
    background: colors.primaryBackground,
    width: '480px',
    borderRadius: '16px',
    alignItems: 'flex-start',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  open: {
    animationName: stylex.keyframes({
      '0%': { transform: 'translate(0, -50px)' },
      '100%': {
        transition: 'transform .3s ease-out, opacity .3s ease-out, visibility .3s ease-out, z-index .3s step-end',
      },
    }),
    animationDuration: '.3s',
  },
  header: {
    fontSize: typography['fontSizeBody-1'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighDemi,
    lineHeight: typography['lineHeightBody-1'],
    color: colors.primaryText,
    display: 'flex',
    padding: '16px 24px',
    alignItems: 'flex-start',
    gap: '8px',
  },
  message: {
    fontSize: typography['fontSizeCaption-1'],
    fontStyle: 'normal',
    fontWeight: typography.fontWeighRegular,
    lineHeight: typography['lineHeightCaption-1'],
    alignSelf: 'stretch',
    color: colors.secondaryText,
    padding: '0 24px',
    gap: '16px',
    display: 'flex',
    flexDirection: 'column',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '16px 24px',
    gap: '8px',
  },
});

import { ButtonProps } from '@sminex/sminex-uikit/dist/src/ui-components/button/model/types';
import { FC } from 'react';

import stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

const styles = stylex.create({
  button: {
    cursor: 'pointer',
    fontFamily: fonts.din2014Regular,
    color: colors.primaryText,
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    minWidth: '177px',
    padding: '8px 20px',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '2px ',
  },

  children: {
    color: colors.accentText,
  },
});

const PlusIcon: FC = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.125C10.8452 3.125 11.125 3.40482 11.125 3.75V9.375H16.75C17.0952 9.375 17.375 9.65482 17.375 10C17.375 10.3452 17.0952 10.625 16.75 10.625H11.125V16.25C11.125 16.5952 10.8452 16.875 10.5 16.875C10.1548 16.875 9.875 16.5952 9.875 16.25V10.625H4.25C3.90482 10.625 3.625 10.3452 3.625 10C3.625 9.65482 3.90482 9.375 4.25 9.375H9.875V3.75C9.875 3.40482 10.1548 3.125 10.5 3.125Z"
        fill={colors.accentBackground}
      />
    </svg>
  );
};

export const AddButton: FC<ButtonProps> = ({ children, onClick, settings }, props) => {
  return (
    <button onClick={onClick} {...stylex.props(styles.button)} settings={settings} {...props}>
      <div {...stylex.props(styles.icon)}>
        <PlusIcon />
      </div>
      <div {...stylex.props(styles.children)}>{children}</div>
    </button>
  );
};

import * as stylex from '@stylexjs/stylex';
import { colors } from '../../shared/tokens/colors.stylex';
import { fonts } from '../../shared/tokens/fonts.stylex';
import { typography } from '../../shared/tokens/typography.stylex';

export const styles = stylex.create({
  container: {
    background: colors.deepBackground,
    width: '100%',
    // height: '100vh',
    padding: '80px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
    paddingTop: '58px',
    maxWidth: '1280px',
    width: {
      default: '1280px',
      '@media (max-width: 1439px)': '954px',
    },
    justifyContent: 'start',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    maxWidth: '1280px',
    width: {
      default: '1280px',
      '@media (max-width: 1439px)': '954px',
    },
  },
  title: {
    fontFamily: fonts.din2014Regular,
    fontSize: typography['fontSizeHeading-3'],
    fontWeight: typography.fontWeighDemi,
    lineHeight: '36px',
    color: colors.primaryText,
  },
  pagination: {
    paddingTop: '24px',
  },
});

import { Button, Modal, PreloaderCircular } from '@sminex/sminex-uikit';
import { createPortal } from 'react-dom';
import stylex from '@stylexjs/stylex';
import { styles } from './styles';
import { FC, useEffect, useState } from 'react';
import { TextField } from '@sminex/sminex-uikit';

interface IModalProps {
  closeHandle: () => void;
  sendTickets: (message: string) => void;
  active: boolean;
  isLoading: boolean;
}

const LENS_MESSAGE = 70;
export const ModalTickets: FC<IModalProps> = ({ active, closeHandle, sendTickets, isLoading }) => {
  const [message, setMessage] = useState('');
  useEffect(() => {
    setMessage('');
  }, [active]);

  const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= LENS_MESSAGE) {
      setMessage(event.target.value);
    }
  };

  const handleClick = () => {
    if (message) {
      sendTickets(message);
    }
  };
  if (!active) return;
  return createPortal(
    <Modal active={active} settingsContainer={{ height: 'auto', width: '600px' }}>
      {isLoading ? (
        <div {...stylex.props(styles.container)}>
          <div {...stylex.props(styles.header)}>Отправка приглашения</div>
          <div {...stylex.props(styles.loader)}>
            <PreloaderCircular variant="secondary" size="large" />
          </div>
        </div>
      ) : (
        <div {...stylex.props(styles.container)}>
          <p {...stylex.props(styles.header)}>Отправить приглашения</p>
          <TextField
            labelMsg="Текст сообщения"
            changeFunction={changeHandler}
            value={message}
            settings={{ width: '100%', height: '200px', cursor: 'text' }}
            status={message.length < LENS_MESSAGE ? 'default' : 'error'}
            textareaMessage={
              message.length < LENS_MESSAGE ? String(message.length) : 'Максимальное колличество символов'
            }
          />
          <div {...stylex.props(styles.actions)}>
            <Button onClick={() => closeHandle()} variant="secondary">
              Отмена
            </Button>
            <Button onClick={handleClick} variant="accent">
              Отправить
            </Button>
          </div>
        </div>
      )}
    </Modal>,
    document.getElementById('modal') as Element
  );
};

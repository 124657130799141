import stylex from '@stylexjs/stylex';

import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

export const styles = stylex.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: fonts.din2014Regular,
    gap: '26px',
    minHeight: '200px',
  },

  header: {
    padding: 0,
    color: colors.primaryText,
    fontSize: '24px',
    fontFamily: fonts.din2014Regular,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    gap: '20px',
  },
  loader: {
    margin: '0 auto',
  },

  switcherLabel: {
    fontSize: '14px',
    paddingLeft: '8px',
    color: colors.primaryText,
  },
  row: { display: 'flex', flexDirection: 'row', alignItems: 'center' },

  input: {
    paddingBottom: '14px',
  },
});

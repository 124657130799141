import React, { ReactNode, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import ModalWrapper from '@shared/modal-wrapper/index.tsx';
import EmployeeSwitcher from '@entities/employee-switcher/index.tsx';
import CompaniesStructure from './companies-structure.tsx';
import { FreelanceEmployee } from './freelance-employee.tsx';
import WithExcel from './with-excel.tsx';
import { IFreelanceEmployee } from '@shared/types/types.ts';
import { useCreateTickets } from '@pages/event/api/useCreateTickets.ts';
import { cleanPhoneNumber } from '@shared/libs/clean-number.ts';
import { useCreateUser } from '@features/api/use-create-user.ts';
export interface ISegmentParticipants {
  id: number;
  title: string;
  eventId?: number;
  freelanceEmployee?: IFreelanceEmployee[];
  companiesStructureList?: {
    [key: number]: number;
  };
  companiesStructureTree?: {
    tags?: Array<{
      id?: number;
      title?: string;
    }>;
  };
}

interface AddParticipantsModalProps {
  isOpenModal: boolean;
  setIsOpenModal: (isOpenModal: boolean) => void;
  segment: { id: number; title: string; eventId: number };
  // onChange: (segment: ISegmentParticipants) => void;
  invitedParticipants: number[];
}

export const AddParticipantsModal: React.FC<AddParticipantsModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  segment,
  // onChange,
  invitedParticipants,
}) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedParticipants, setSelectedParticipants] = useState<number[]>([]);
  // const [checkedState, setCheckedState] = useState<{ [key: number]: number }>({});
  const [freelanceEmployees, setFreelanceEmployees] = useState<IFreelanceEmployee[]>([]);

  const createTickets = useCreateTickets();
  const { createUser } = useCreateUser();

  // TODO: реализовать импорт Excel
  const onFileChange = (file: File) => {
    console.log(file);
  };

  // useEffect(() => {
  //   if (!segment) return;
  //   segment.companiesStructureList && setCheckedState(segment.companiesStructureList);
  //   segment.freelanceEmployee && setFreelanceEmployees(segment.freelanceEmployee);
  // }, [segment, isOpenModal]);

  const handelChangeSegment = async () => {
    const ids = [...selectedParticipants];

    if (freelanceEmployees) {
      for (const employee of freelanceEmployees) {
        const res = await createUser({
          firstName: employee.firstName,
          lastName: employee.lastName,
          phone: cleanPhoneNumber(employee.phone),
          email: employee.email,
        });
        if (res.data) {
          ids.push(res.data.createUser.id);
        }
      }
    }
    await createTickets(ids, segment.eventId, segment.id);
  };

  const formsForAdd: ReactNode[] = [
    <CompaniesStructure
      invitedParticipants={invitedParticipants}
      selectedParticipants={selectedParticipants}
      setSelectedParticipants={setSelectedParticipants}
    />,
    <FreelanceEmployee freelanceEmployees={freelanceEmployees} onChange={setFreelanceEmployees} />,
    <WithExcel onFileChange={onFileChange} />,
  ];

  const checkingBeforeAdding = (boolean: boolean) => {
    // const hasEmptyName = freelanceEmployees.some((employee) => employee.name.trim() === '');
    // const hasEmptyMail = freelanceEmployees.some((employee) => employee.email.trim() === '');
    // const hasEmptyPhone = freelanceEmployees.some((employee) => employee.phone.trim() === '');
    // if (hasEmptyName || hasEmptyMail || hasEmptyPhone) {
    //   return;
    // }
    setIsOpenModal(boolean);
    handelChangeSegment();
  };

  return (
    <ModalWrapper
      active={isOpenModal}
      title={`Редактирование участников: ${segment.title}`}
      size="small"
      settingsContainer={{ height: 'auto' }}
      settingsHeader={{ width: '596px' }}
      buttons={[
        {
          children: <p {...stylex.props(styles.textBtn, styles.cancelBtn)}>Отмена</p>,
          variant: 'secondary',
          onClick: () => {
            setSelectedParticipants([]);
            setIsOpenModal(false);
          },
          settings: { width: '97px' },
        },
        {
          children: <p {...stylex.props(styles.textBtn, styles.acceptBtn)}>Добавить</p>,
          variant: 'accent',
          onClick: () => {
            checkingBeforeAdding(false);
          },
          settings: { width: '97px' },
        },
      ]}
    >
      <EmployeeSwitcher
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        blocks={['Орг. структура', 'Внештатный участник', 'Импортировать из Excel']}
      />
      {formsForAdd[activeIndex]}
    </ModalWrapper>
  );
};

import { Checkbox, Input } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import { useEffect, useState } from 'react';
import './styles.css';
import { gql, useQuery } from 'urql';

const UsersQuery = gql`
  query GetUsers {
    getUsers {
      data {
        id
        firstName
        lastName
        avatar
      }
    }
  }
`;

interface CompaniesStructureProps {
  // checkedState: {
  //   [key: string]: boolean;
  // };
  invitedParticipants: number[];
  // setCheckedState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  setSelectedParticipants: (users: number[]) => void;
  selectedParticipants: number[];
}

interface Participant {
  id: number;
  firstName: string;
  lastName: string;
  avatar: null | string;
}

const CompaniesStructure: React.FC<CompaniesStructureProps> = ({
  setSelectedParticipants,
  invitedParticipants = [],
  selectedParticipants,
}) => {
  // TODO: внести в отдельный модуль
  const useFetchUsers = () => {
    const [{ error, fetching, data }] = useQuery({ query: UsersQuery });
    const users = data?.getUsers && data.getUsers;
    return { error, users, fetching };
  };

  const { users } = useFetchUsers();

  const [inputText, setInputText] = useState('');
  const [filteredData, setFilteredData] = useState<Participant[]>([]);

  useEffect(() => {
    if (users && users.data) {
      setFilteredData(users.data);
    }
  }, [users]);

  const handleCheck = (participant: Participant) => {
    // checkedState[participant.id] = !checkedState[participant.id];
    // setCheckedState({ ...checkedState });
    if (selectedParticipants.includes(participant.id)) {
      setSelectedParticipants(selectedParticipants.filter((el) => el !== participant.id));
      return;
    }
    setSelectedParticipants([...selectedParticipants, participant.id]);
  };

  // const selectedParticipants = Object.values(checkedState).filter((el) => el);
  const statusAllChecked = users && users.data.length === invitedParticipants.length;
  // const isIndeterminate = Object.values(checkedState).some((el) => el);

  const handleAllCheck = () => {
    // const newCheckedState: { [key: string]: boolean } = {};
    // users && users.data.map((participant: Participant) => (newCheckedState[participant.id] = !statusAllChecked));
    // setCheckedState(newCheckedState);
    const newCheckedUsers = users && users.data.map((participant: Participant) => participant.id);
    setSelectedParticipants(newCheckedUsers);
  };

  const handleInput = (text: string) => {
    setInputText(text);
    const lookingText = text.trim().toLocaleLowerCase();

    const filtered =
      users &&
      users.data.filter(
        (participant: Participant) =>
          participant.firstName.trim().toLowerCase().includes(lookingText) ||
          participant.lastName.trim().toLowerCase().includes(lookingText)
      );
    setFilteredData(filtered);
  };

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(styles.inputWithSelectedParticipant)}>
        <div {...stylex.props(styles.input)}>
          <Input
            type="search"
            status="default"
            placeholder="Поиск"
            value={inputText}
            size="small"
            settings={{ width: '546px' }}
            changeFunction={handleInput}
          />
        </div>
        <div {...stylex.props(styles.participant)}>
          Количество выбранных участников: {[...invitedParticipants, ...selectedParticipants].length}
        </div>
      </div>
      <div {...stylex.props(styles.blockAllList)}>
        <div {...stylex.props(styles.checkboxWithName)}>
          <Checkbox onChange={handleAllCheck} size={'small'} isChecked={statusAllChecked} isIndeterminate={false} />
          <div {...stylex.props(styles.participant)}>Выбрать всех сотрудников</div>
        </div>

        <div className="custom-scroll">
          <div {...stylex.props(styles.allList)}>
            {filteredData.map((participant, index) => (
              <div key={index} {...stylex.props(styles.checkboxWithName)} onClick={() => handleCheck(participant)}>
                <Checkbox
                  onChange={() => {}}
                  size={'small'}
                  isChecked={[...invitedParticipants, ...selectedParticipants].some((el) => el === participant.id)}
                />
                <div {...stylex.props(styles.participant)}>
                  {participant.firstName} {participant.lastName}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompaniesStructure;

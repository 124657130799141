import { additionalProps, TreeNode } from '@sminex/sminex-uikit/dist/src/ui-components/tree/model/types';
import { ITab } from '../ui/tabs';

export const NODE: TreeNode & additionalProps = {
  id: 1,
  title: 'Президент',
  isChecked: false,
  isIndeterminate: false,
  content: [
    {
      id: 2,
      title: 'Департамент Согласований и Регистраций',
      isChecked: false,
      isIndeterminate: false,
      position: '123',
      content: [
        {
          id: 3,
          title: 'Текст пункта',
          isChecked: false,
          position: '123',
          isIndeterminate: false,
          sendInvitation: true,
          invitation: 'accepted',
          qr: 'sended',
          presence: 'true',
          photo: '',
          content: [],
        },
        {
          id: 4,
          title: 'Текст пункта',
          isChecked: false,
          isIndeterminate: false,
          content: [
            {
              id: 5,
              title: 'Текст пункта',
              isChecked: false,
              isIndeterminate: false,
              sendInvitation: true,
              invitation: 'accepted',
              qr: 'sended',
              presence: 'true',
              photo:
                'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
              content: [],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      title: 'Департамент Прибыли Проектов',
      isChecked: false,
      isIndeterminate: false,
      sendInvitation: true,
      invitation: 'accepted',
      qr: 'sended',
      presence: 'true',
      photo:
        'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
      content: [],
    },
    {
      id: 7,
      title: 'Отдел обеспечения управления проектами',
      isChecked: false,
      isIndeterminate: false,
      content: [
        {
          id: 8,
          title: 'Отдел обеспечения управления проектами',
          isChecked: false,
          isIndeterminate: false,
          sendInvitation: true,
          invitation: 'accepted',
          qr: 'sended',
          presence: 'true',
          photo:
            'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
          content: [],
        },
        {
          id: 9,
          title: 'Отдел управления проектами 1',
          isChecked: false,
          isIndeterminate: false,
          content: [
            {
              id: 10,
              title: 'Иванов Константин',
              isChecked: false,
              isIndeterminate: false,
              sendInvitation: true,
              invitation: 'accepted',
              qr: 'sended',
              presence: 'true',
              photo:
                'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
              content: [],
            },
            {
              id: 11,
              title: 'Петровский Сергей',
              isChecked: false,
              isIndeterminate: false,
              sendInvitation: true,
              invitation: 'accepted',
              qr: 'sended',
              presence: 'true',
              photo:
                'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
              content: [],
            },
            {
              id: 12,
              title: 'Сидоров Евгений',
              isChecked: false,
              isIndeterminate: false,
              sendInvitation: true,
              invitation: 'accepted',
              qr: 'sended',
              presence: 'true',
              photo:
                'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
              content: [],
            },
          ],
        },
        {
          id: 13,
          title: 'Текст пункта',
          isChecked: false,
          isIndeterminate: false,
          sendInvitation: true,
          invitation: 'accepted',
          qr: 'sended',
          presence: 'true',
          photo:
            'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
          content: [],
        },
      ],
    },
    {
      id: 14,
      title: 'Отдел управления проектами 1',
      isChecked: false,
      isIndeterminate: false,
      content: [
        {
          id: 15,
          title: 'Текст пункта',
          isChecked: false,
          isIndeterminate: false,
          sendInvitation: true,
          invitation: 'accepted',
          qr: 'sended',
          presence: 'true',
          photo:
            'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
          content: [],
        },
      ],
    },
    {
      id: 16,
      title: 'Проектная группа Лаврушинский',
      isChecked: false,
      isIndeterminate: false,
      sendInvitation: true,
      invitation: 'accepted',
      qr: 'sended',
      presence: 'true',
      photo:
        'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
      content: [],
    },
    {
      id: 17,
      title: 'Текст пункта',
      isChecked: false,
      isIndeterminate: false,
      content: [
        {
          id: 18,
          title: 'Текст пункта',
          isChecked: false,
          isIndeterminate: false,
          content: [
            {
              id: 19,
              title: 'Текст пункта',
              isChecked: false,
              isIndeterminate: false,
              sendInvitation: true,
              invitation: 'accepted',
              qr: 'sended',
              presence: 'true',
              photo:
                'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
              content: [],
            },
          ],
        },
        {
          id: 20,
          title: 'Текст пункта',
          isChecked: false,
          isIndeterminate: false,
          sendInvitation: true,
          invitation: 'accepted',
          qr: 'sended',
          presence: 'true',
          photo:
            'https://sun9-53.userapi.com/impg/sv3_UPqjtvdsbHLGU2kvubK3O6BPaWBjZCkX8A/kSzpWwTdTYo.jpg?size=762x762&quality=95&sign=98ef9427d5852c6229f6ce1ad8ea718f&c_uniq_tag=x0GPxqjRGG1f7qpeVJVLJ3F1_2-hnF_DCY900BbZ5Lw&type=album',
          content: [],
        },
      ],
    },
  ],
};

export interface ISegment {
  id: number;
  title: string;
  participant: TreeNode | object;
}

export const SEGMENTS: ISegment[] = [{ id: 1, title: 'Команда 1', participant: {} }];

export const TABS_LIST: ITab[] = [
  { id: 0, title: 'Список участников' },
  { id: 1, title: 'Организаторы' },
];

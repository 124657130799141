import InputFile from '@entities/input-file';
import React, { useRef, useState } from 'react';
import * as stylex from '@stylexjs/stylex';
import { styles } from './styles.ts';
import { DatePicker, Input } from '@sminex/sminex-uikit';
import { CreateEventFormValues } from '@shared/types/types.ts';

interface FirstStepFormProps {
  eventFormValues: CreateEventFormValues;
  onChangeDate: (date: [Date | null, Date | null]) => void;
  onFileChange: (files: File) => void;
  onChangeName: (value: string) => void;
}

const FirstStepForm: React.FC<FirstStepFormProps> = ({ eventFormValues, onChangeDate, onFileChange, onChangeName }) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const datePickerRef = useRef(null as HTMLDivElement | null);

  return (
    <>
      <div>
        <label {...stylex.props(styles.label)}>Изображение*</label>
        {previewUrl ? (
          <>
            <div {...stylex.props(styles.imgPreview)}>
              <img src={previewUrl} />
            </div>
            <button {...stylex.props(styles.deleteFile)} onClick={() => setPreviewUrl(null)}>
              Удалить
            </button>
          </>
        ) : (
          <InputFile setPreviewUrl={setPreviewUrl} onFileChange={onFileChange} />
        )}
      </div>
      <div {...stylex.props(styles.input)}>
        <Input
          labelText="Название мероприятия*"
          size="small"
          placeholder="Введите название"
          settings={{ width: '320px' }}
          changeFunction={onChangeName}
          value={eventFormValues.name}
        />
      </div>
      <div {...stylex.props(styles.input)} ref={datePickerRef}>
        <DatePicker
          labelText="Дата мероприятия"
          size="small"
          selectedDate={new Date()}
          isRange
          setNewSelectedDate={onChangeDate}
        />
      </div>
    </>
  );
};

export default FirstStepForm;

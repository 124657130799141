import { gql, useQuery } from 'urql';

const QrCodeQuery = gql`
  query GetTicketQrcode($id: Int!, $qrCodeSize: Int!) {
    getTicketQrcode(input: { id: $id, qrCodeSize: $qrCodeSize })
  }
`;

const useFetchQrCode = (id: number, qrCodeSize: number) => {
  const [result] = useQuery({
    query: QrCodeQuery,
    variables: { id, qrCodeSize },
  });
  const { data, fetching, error } = result;
  return {
    data,
    fetching,
    error,
  };
};
export default useFetchQrCode;

import { ButtonProps } from '@sminex/sminex-uikit/dist/src/ui-components/button/model/types';
import { FC, ReactNode } from 'react';

import stylex from '@stylexjs/stylex';
import { fonts } from '@shared/tokens/fonts.stylex';
import { colors } from '@shared/tokens/colors.stylex';

const styles = stylex.create({
  button: {
    cursor: 'pointer',
    fontFamily: fonts.din2014Regular,
    color: colors.primaryText,
    fontSize: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    userSelect: 'none',
  },
});

interface IIconProps extends Omit<ButtonProps, 'children'> {
  icon?: ReactNode;
  children?: ReactNode;
  iconPosition?: 'left' | 'right';
}

export const IconButton: FC<IIconProps> = ({ icon, children, onClick, settings, iconPosition = 'left' }, props) => {
  return (
    <a onClick={onClick} {...stylex.props(styles.button)} settings={settings} {...props}>
      {iconPosition === 'left' && icon}
      {children}
      {iconPosition === 'right' && icon}
    </a>
  );
};

import stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { styles } from './styles';

import { Button } from '@sminex/sminex-uikit';
import { EmptyCard } from '../../pages/event/ui/empty-card';
import { OrganizerList } from '../organizer-list';

import { AddButton } from '@pages/event/ui/add-button';
import ButtonWithArrows from '@shared/ui/button-with-arrows';
import { IOrganizer } from '../organizer/model/types';

interface OranizerTabProps {
  organizers: IOrganizer[];
  handleChange: (organizer: IOrganizer) => void;
  handleDelete: (organizer: IOrganizer) => void;
  handleAdd: () => void;
  handleSort: () => void;
  sendTickets: (users: number[]) => void;
}

export const OranizerTab: FC<OranizerTabProps> = (props) => {
  const { organizers, handleChange, handleDelete, handleAdd, handleSort, sendTickets } = props;

  const handleSendQrCodes = () => {
    organizers.filter((o) => o.access);
    if (organizers.length) {
      sendTickets(organizers.filter((o) => o.access).map((user) => user.id as number));
    }
  };

  return (
    <>
      {organizers.length == 0 ? (
        <EmptyCard handleAdd={handleAdd} />
      ) : (
        <>
          <div {...stylex.props(styles.buttonsContainer)}>
            <Button onClick={() => handleSendQrCodes()} size="small" variant="secondary">
              Отправить QR-код
            </Button>
          </div>
          <div {...stylex.props(styles.infoContainer)}>
            <ButtonWithArrows onClick={handleSort} children="Организаторы"></ButtonWithArrows>
            <div {...stylex.props(styles.accessText)}>Доступ к мероприятию</div>
          </div>
          <OrganizerList organizers={organizers} onChange={(e) => handleChange(e)} onDelete={handleDelete} />
          <AddButton
            onClick={handleAdd}
            settings={{
              fontSize: '14px',
            }}
          >
            Добавить организатора
          </AddButton>
        </>
      )}
    </>
  );
};

import { Organizer } from '@features/organizer';
import { IOrganizer } from '@features/organizer/model/types';
import { useConfirm } from '@shared/hooks/useConfirm';
import { Bin } from '@shared/ui/bin';
import stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { IconButton } from '../../pages/event/ui/icon-button';
import { styles } from './styles';

interface IOrgnizersProps {
  organizers: IOrganizer[];
  onChange: (organizer: IOrganizer) => void;
  onDelete: (organizer: IOrganizer) => void;
}

export const OrganizerList: FC<IOrgnizersProps> = ({ organizers, onChange, onDelete }) => {
  const { openConfirmDialog } = useConfirm();

  const handleDeleteSegment = async (organizer: IOrganizer) => {
    if (
      await openConfirmDialog(
        `Удалить организатора ${organizer.firstName} ${organizer.lastName} ?`,
        'Подтвердите удаление',
        'Удалить'
      )
    ) {
      onDelete(organizer);
    }
  };
  return (
    <div {...stylex.props(styles.container)}>
      {organizers.map((organizer) => (
        <Organizer
          actions={
            <IconButton icon={<Bin />} onClick={() => handleDeleteSegment(organizer)}>
              <div {...stylex.props(styles.menuItem)}></div>
            </IconButton>
          }
          key={organizer.id}
          organizer={organizer}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

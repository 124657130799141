import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from '@features/auth/login-form';
import { useLogin, ILoginVariables } from '@features/auth/hooks/useLogin';
import { useAuth } from '@features/auth/hooks/useAuth';

export const AuthPage: FC = () => {
  const { login: authLogin } = useAuth();
  const [{ error }, login] = useLogin();

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    error && setErrorMessage(error.message);
  }, [error]);

  const navigate = useNavigate();
  const handleClick = async (userState: ILoginVariables) => {
    const result = await login(userState);
    if (result.data) {
      authLogin(userState.email, result.data.signIn, '');
      navigate('/');
    }
  };
  return <LoginForm onSubmit={handleClick} errorMessage={errorMessage} onChange={() => setErrorMessage('')} />;
};

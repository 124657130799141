import useFetchQrCode from '@shared/hooks/useFetchQrCode';
import { QrCode } from '@shared/ui/qr-code';
import { PreloaderCircular } from '@sminex/sminex-uikit';
import * as stylex from '@stylexjs/stylex';
import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { styles } from './styles';

export const QrPage: FC = () => {
  const { id } = useParams();
  const { data, fetching, error } = useFetchQrCode(parseInt(id as string, 10), 261);
  /*
 useFetchQrCode вторым аргументом принимает размер QR-кода в пикселях, 261 подогнан под иконку sminex-illustration
  */

  if (fetching)
    return (
      <div {...stylex.props(styles.preloaderContainer)}>
        <PreloaderCircular variant="secondary" />
      </div>
    );
  if (error) return <Navigate to="/error" />;

  return (
    <div {...stylex.props(styles.container)}>
      {data?.getTicketQrcode && (
        <>
          <QrCode svg={data.getTicketQrcode} />
          <p {...stylex.props(styles.title)}>Ваш QR</p>
          <p {...stylex.props(styles.subTitle)}>Для прохода на мероприятие предъявите его организаторам</p>
        </>
      )}
    </div>
  );
};

import { Accordion } from '@features/accordion';
import { EventParticipantCard } from '@features/event-participant-card';
import { Checkbox, Input } from '@sminex/sminex-uikit';
import stylex from '@stylexjs/stylex';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Segment } from '../segment-header';
import { styles } from './styles';
import { useConfirm } from '@shared/hooks/useConfirm';
import { useFetchTicketQr } from '@pages/event/api/useFetchTicketQr';

import { ISegmentParticipants } from '@entities/add-participants-modal';
import { ITicket, useFetchGetTicketsSegment } from '@pages/event/api/useFetchGetTicketsSegment';
import { useFetchDeleteTicket } from '@pages/event/api/useFetchDeleteTickets';

interface ISegmentCardProps {
  segment: ISegmentParticipants;
  checkedUsers: { [key: number]: number };
  onDeleteSegment: (segment: ISegmentParticipants) => void;
  onAddSegment: (users: number[]) => void;
  toggleUser: (user: ITicket) => void;
  toggleAllUsers: (users: { userId: number; ticketId: number }[], isChecked: boolean) => void;
  onToggle: (isOpen: boolean) => void;
}

export const SegmentCard: FC<ISegmentCardProps> = ({
  segment,
  onDeleteSegment,
  onAddSegment,
  checkedUsers,
  toggleUser,
  toggleAllUsers,
  onToggle,
}) => {
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const { openConfirmDialog } = useConfirm();
  const [isOpen, setIsOpen] = useState(false);
  const [currentTicketId, setCurrentTicketId] = useState(0);
  const [participants, setParticipants] = useState<ITicket[]>([]);

  const { data: ticketData, refetch } = useFetchGetTicketsSegment(segment.id, isOpen);
  const [, deleteTicket] = useFetchDeleteTicket();

  useEffect(() => {
    if (ticketData) {
      setParticipants(ticketData.getTicketSegmentById.tickets);
    }
  }, [ticketData]);

  const handleDelete = async (ticket: ITicket) => {
    const res = await openConfirmDialog(`Удалить участника ${ticket.user.firstName} ${ticket.user.lastName} ?`);
    if (res) {
      deleteTicket({ id: ticket.id }).then(() => {
        setParticipants(participants.filter((item) => item.id !== ticket.id));
      });
    }
  };

  const handleChange = useCallback((string: React.SetStateAction<string>) => {
    setSearch(string);
  }, []);

  const { data } = useFetchTicketQr(currentTicketId);
  //  qr downlopad
  useEffect(() => {
    if (data !== undefined) {
      window.open(`/qr/${currentTicketId}`, '_blank');
      const a = document.createElement('a');
      document.body.appendChild(a);
      const blob = new Blob([data.getTicketQrcode], { type: 'image/svg+xml' });
      const file = window.URL.createObjectURL(blob);
      a.href = file;
      a.download = file;
      a.click();
      window.open(file, '_blank');
    }
  }, [data]);

  const filteredUsers = useMemo(() => {
    return search
      ? participants.filter((item) => item.user.firstName.trim().toLowerCase().includes(search.trim().toLowerCase()))
      : participants;
  }, [search, participants]);

  return (
    <Accordion
      onToggle={(value) => {
        onToggle(value);
        setIsOpen(value);
      }}
      key={segment.id}
      element={
        <Segment
          isIndeterminate={false}
          checked={checked}
          title={segment.title}
          onChange={() => {
            setChecked(!checked);
            const users = participants.map((item) => ({ userId: item.user.id, ticketId: item.id }));
            toggleAllUsers(users, !checked);
          }}
          onAddParticipants={() => {
            onAddSegment(participants.map((item) => item.user.id));
            refetch();
          }}
          onDelete={() => {
            onDeleteSegment(segment);
          }}
        />
      }
    >
      <>
        <div {...stylex.props(styles.input)}>
          <Input value={search} changeFunction={handleChange} size="small" settings={{ width: 'auto' }} type="search" />
        </div>
        <div {...stylex.props(styles.column)}>
          {filteredUsers.map((ticket) => (
            <div key={ticket.id} {...stylex.props(styles.row)}>
              <Checkbox isChecked={!!checkedUsers[ticket.id]} onChange={() => toggleUser(ticket)} />
              <EventParticipantCard
                ticket={ticket}
                key={ticket.id}
                photo={ticket?.user?.avatar}
                position={ticket?.user?.position}
                name={`${ticket?.user?.firstName} ${ticket?.user?.lastName}`}
                onDelete={() => handleDelete(ticket)}
                getTicketQR={() => setCurrentTicketId(ticket.id)}
              />
            </div>
          ))}
        </div>
      </>
    </Accordion>
  );
};

import React, { SetStateAction } from 'react';
import { Button, Input } from '@sminex/sminex-uikit';
import { styles } from './styles';
import * as stylex from '@stylexjs/stylex';
import PlusIcon from '@shared/ui/plus-icon';
import { XmarkIcon } from '@shared/ui/x-mark-icon';
import { Pencil } from '@shared/ui/pencil';
import { Segment } from '@entities/second-step-form';
import { XmarkIconInput } from '@shared/ui/x-mark-icon-input';

export interface SegmentDetailsCardProps {
  changeFunctionInputSearch: (value: string) => void;
  onClickAdd?: () => void;
  setIsOpenModal: (isOpenModal: boolean) => void;
  segment: Segment;
  onChangeComStructure: (segment: Segment) => void;
  setCurrenSegments: React.Dispatch<SetStateAction<Segment[]>>;
  deleteSegmentHandler: (segmentId: number) => void;
}

const SegmentDetailsCard: React.FC<SegmentDetailsCardProps> = (props) => {
  const { changeFunctionInputSearch, setIsOpenModal, segment, onChangeComStructure, deleteSegmentHandler } = props;

  const clickAddHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    setIsOpenModal(true);
  };

  const allSelectedParticipantsCount = Object.values(segment.companiesStructureList).filter((el) => el).length;

  const deleteAllSelectedParticipantsHandler = () => {
    onChangeComStructure({ ...segment, companiesStructureList: {} });
  };

  const deleteFreelanceEmployeeHandler = (onefreelanceEmployeeId: number) => {
    const filteredFreelanceEmployee = segment.freelanceEmployee.filter((el) => el.id !== onefreelanceEmployeeId);
    onChangeComStructure({
      ...segment,
      freelanceEmployee: filteredFreelanceEmployee,
    });
  };

  return (
    <>
      <div {...stylex.props(styles.base)} data-testid="segment-details-card">
        <div {...stylex.props(styles.inputWithXmark)}>
          <Input
            type="text"
            size="small"
            value={segment.name}
            changeFunction={changeFunctionInputSearch}
            settings={styles.input}
          />
          <XmarkIconInput onClick={() => deleteSegmentHandler(segment.id)} />
        </div>

        {segment && (
          <>
            {allSelectedParticipantsCount > 0 && (
              <div {...stylex.props(styles.titleWithTagBlock)}>
                <div {...stylex.props(styles.titleTagBlock)}>Орг. структура</div>
                <div {...stylex.props(styles.oneTag)}>
                  <div {...stylex.props(styles.textInTag)}>Выбранные участники: {allSelectedParticipantsCount}</div>
                  <XmarkIcon onClick={() => deleteAllSelectedParticipantsHandler()} />
                </div>
              </div>
            )}
            {segment.freelanceEmployee.length > 0 && (
              <div {...stylex.props(styles.titleWithTagBlock)}>
                <div {...stylex.props(styles.titleTagBlock)}>Внештатный участник</div>
                <div {...stylex.props(styles.tagBlock)}>
                  {segment.freelanceEmployee.map((onefreelanceEmployee) => (
                    <div {...stylex.props(styles.oneTag)}>
                      <div key={onefreelanceEmployee.id} {...stylex.props(styles.textInTag)}>
                        {onefreelanceEmployee.lastName} {onefreelanceEmployee.firstName}
                      </div>
                      <XmarkIcon onClick={() => deleteFreelanceEmployeeHandler(onefreelanceEmployee.id)} />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {(allSelectedParticipantsCount > 0 || segment.freelanceEmployee.length > 0) && (
              <Button variant="link" size="small" onClick={clickAddHandler}>
                <div {...stylex.props([styles.contentButtonChildren])}>
                  <Pencil />
                  <p {...stylex.props(styles.textButton)}>Редактировать участников</p>
                </div>
              </Button>
            )}
          </>
        )}
        {allSelectedParticipantsCount === 0 && segment.freelanceEmployee.length === 0 && (
          <>
            <p {...stylex.props(styles.infoAvailable)}>
              Участники не добавлены. Нажмите кнопку <br /> ниже для добавления
            </p>
            <Button variant="link" size="small" onClick={clickAddHandler}>
              <div {...stylex.props([styles.contentButtonChildren])}>
                <PlusIcon />
                <p {...stylex.props(styles.textButton)}>Добавить участников</p>
              </div>
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default SegmentDetailsCard;
